<template>
  <div class="header">
    <div class="top">
      <div class="tops_content flex">
        <div class="logo">
          <router-link to="/">
            <img
                src="~static/lanzhou/logo.png"
                alt="兰州市残疾人就业创业网络服务平台（去首页）"
            />
          </router-link>
        </div>
        <div class="location locations" @click="citys = true">
          <i class="el-icon-location"></i>
          <a href="javascript:void(0);" class="select">{{ location }}</a>
        </div>
        <div class="location" @click="citys = true">
          <img src="~static/public/select.png" alt="">
          <a href="javascript:void(0);" class="select">切换城市</a>
        </div>
        <div class="world search">
          <el-input
              v-model="search"
              class="input-with-select"
              :placeholder="select == 1 ? '请输入职位关键词' : '请输入公司关键词'"
          >
            <el-select
                v-model="select"
                slot="prepend"
                placeholder="职位"
                aria-label="下拉列表框"
            >
              <el-option label="职位" value="1"></el-option>
              <el-option label="公司" value="2"></el-option>
            </el-select>
            <el-button
                aria-label="搜索"
                slot="append"
                icon="el-icon-search"
                style="fontsize: 21px"
                @click="goJob(search, select)"
            >搜索</el-button>
          </el-input>
        </div>
        <div class="login">
          <a class="map" title="无障碍浏览" @click="open()"
          >无障碍浏览
          </a>
        </div>
        <div class="logins flex" @mouseover="loginAlert = true" v-if="!isLogin">
          登录 | 注册
        </div>
        <head-drop class="login_text" v-else></head-drop>
        <div class="login_alert" v-show="loginAlert">
          <el-button type="primary" @click="goRouter('/Login/')"
          ><img src="~/static/login/user1.png" alt=""/>我要求职
          </el-button>
          <el-button
              type="primary"
              class="blue"
              @click="goRouter('/user/companyLogin')"
          ><img src="~/static/login/company1.png" alt=""/>我要招聘
          </el-button>
          <a
              href="https://admin.cdpee.org.cn/"
              target="_blank"
              @click="loginAlert = false"
          >
            <el-button type="waring" class="org"
            ><img src="~/static/login/jigou1.png" alt=""/>机构登录
            </el-button>
          </a>
        </div>
        <div
            class="weimu"
            v-show="loginAlert"
            title="登录/注册"
            @click="loginAlert = false"
        ></div>
      </div>
    </div>
    <div class="wraper_bottom">
      <div class="bottom">
        <div class="nav">
          <el-menu
              :default-active="activeIndex"
              class="el-menu-demo tab_el_memu"
              mode="horizontal"
              :router="true"
              @select="handleSelect"
              v-if="type == 2"
          >
            <el-menu-item index="/enterprise">
              <div class="li">首页</div>
            </el-menu-item>
            <el-menu-item index="/news/">
              <div class="li">政策要闻</div>
            </el-menu-item>
            <el-menu-item index="/enterprise/post">
              <div class="li">职位管理</div>
            </el-menu-item>
            <el-menu-item index="/enterprise/recruiting">
              <div class="li">找人才</div>
            </el-menu-item>
            <el-menu-item index="/enterprise/netJob">
              <div class="li">网络招聘会</div>
            </el-menu-item>
          </el-menu>
          <el-menu
              :default-active="activeIndex"
              class="el-menu-demo tab_el_memu"
              mode="horizontal"
              :router="true"
              @select="handleSelect"
              v-else
              active-text-color="#28a46d"
          >
            <el-menu-item index="/">
              <div class="li">首页</div>
            </el-menu-item>
            <el-menu-item index="/job/">
              <div class="li">找工作</div>
            </el-menu-item>
            <el-menu-item index="/train/">
              <div class="li">免费培训</div>
            </el-menu-item>
            <el-menu-item index="/news/">
              <div class="li">政策要闻</div>
            </el-menu-item>
            <el-menu-item index="/public/onlineJobfair/">
              <div class="li">招聘会</div>
            </el-menu-item>
            <el-menu-item index="/evaluation/">
              <div class="li">能力测评</div>
            </el-menu-item>
            <el-menu-item index="/blind/">
              <div class="li">盲按机构</div>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <city :show="citys" width="1000px" @close="citys = false"></city>
  </div>
</template>
<script>
import HeadDrop from "@/components/public/HeadDrop";
import city from "@/components/search/allCity";

export default {
  components: {
    HeadDrop,
    city,
  },
  data() {
    return {
      activeIndex: this.$route.path || "/",
      search: "",
      select: "1",
      loginAlert: false,
      isLogin: "",
      type: "",
      citys: false,
      location:'',
    };
  },
  methods: {
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    goJob(job) {
      const {href} = this.$router.resolve({
        path: "/job/",
        query: {
          active: job,
        },
      });
      window.open(href, "_blank");
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      console.log(key, 222, keyPath);
    },
    open() {
      window.toolbar.open();
    },
  },
  created() {
    this.isLogin = JSON.parse(localStorage.getItem("userType")) || "";
    this.type = this.isLogin.userType;
    let text = localStorage.getItem("locationText") || "兰州市";
    let locationSelect = localStorage.getItem("locationSelect");
    if (locationSelect) {
      this.location = locationSelect;
    } else {
      this.location = text;
    }
  },
};
</script>
<style scoped lang="less">
.header {
  background: white;
  padding: 20px 0;

  .top {
    .tops_content {
      width: 1200px;
      margin: 0 auto;
      position: relative;
    }

    .logo {
      margin-right: 10px;

      img {
        height: 44px;
      }
    }

    .search {
      width: 380px;
      text-align: center;
      height: 36px;
      margin-left: 36px;
      /deep/ .el-input-group {
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        .el-input__inner {
          border: none;
        }

        .el-input-group__prepend {
          background-color: #fff !important;
          border: none;
          width: 72px;

          &::after {
            content: "";
            position: absolute;
            top: 6px;
            right: 0;
            width: 1px;
            height: 30px;
            background: #D9D9D9;
          }

          .el-select .el-input .el-input__inner {
            color: #333;
            border: none;
            width: 78px;
          }
        }

        .el-input-group__append {
          color: #fff;
          background-color: #00924b;
        }
      }
    }

    .login {
      flex: 1;
      margin-top: 10px;
      margin-left: 30px;
      a.map {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        padding-left: 24px;
        background-image: url("~static/public/can_gray.png");
        background-size: auto auto;
        background-position: left center;
        background-repeat: no-repeat;
        cursor: pointer;
        &:hover{
          background-image: url("~static/public/can.png");
          color: #00924C;
          text-decoration: underline;
        }
      }
    }

    .logins {
      align-items: center;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 7px;
      &:hover{
        color: #00924C;
        text-decoration: underline;
      }
    }

    .login_text {
      width: 120px;
      height: 30px;
      padding: 5px;
      background: #fc6c38;
      display: inline-block;
      text-align: center;
      color: #fff;
      cursor: pointer;

      /deep/ .el-dropdown-link {
        color: #fff;
      }
    }

    .login_alert {
      position: absolute;
      right: -86px;
      width: 306px;
      padding: 30px;
      background: white;
      border-radius: 0px 0px 6px 6px;
      z-index: 10000;
      margin-top: 30px;

      .el-button {
        width: 246px;
        height: 42px;
        line-height: 42px;
        padding: 0;
        margin-left: 0;
        margin-bottom: 24px;
        border: none;

        img {
          width: 18px !important;
          height: 18px !important;
          vertical-align: middle;
          transform: translateY(-1px);
          margin-right: 5px;
        }
      }

      .blue {
        background: #0090e2;
      }

      .org {
        margin-bottom: 0;
        color: white;
        background: #ff9600;
      }
    }

    .weimu {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      z-index: 9999;
    }
  }

  .wraper_bottom {
    width: 100%;
    background: #ffffff;
    margin-top: 48px;
    .bottom {
      width: 1200px;
      margin: 0 auto;

      .nav {
        ul {
          display: flex;
          border: none;
          justify-content: space-between;

          &::before {
            content: none;
          }

          &::after {
            content: none;
          }

          .el-menu-item {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 25px;
            height: auto;
            &.is-active{
              font-weight: bold;
              border: none;
            }
            &.is-active::after{
              width: 48px;
              height: 4px;
              bottom: -14px;
              background: #00924C;
              border-radius: 1px;
            }
            &:hover {
              font-family: PingFangSC-Regular, PingFang SC;
              color: #333333;
            }
          }
        }
      }
    }
  }

  .location {
    margin-right: 10px;
    display: flex;
    align-items: center;
    img{
      margin-right: 2px;
    }
    a{
      color: #FC6C38;
    }
    .el-icon-location {
      color: #00924C;
      font-size: 18px;
    }
  }
  .locations{
    a{
      color: #333333;
    }
  }
}
</style>
