<template>
  <div class="city">
    <el-dialog title="" :visible.sync="show" top="10vh" :width="width" :before-close="handleClose">
      <div class="title flex" slot="title">
        <div class="name">切换城市</div>
        <div class="close"><i class="el-icon-close" @click="handleClose"></i></div>
      </div>
      <div class="content">
        <div class="location marB20 flex" v-if="locationSite !== ''">
          <span class="name bold">当前选择：</span>
          <h3 class="location_text">{{ locationSelect }}</h3>
        </div>
        <div class="location flex">
          <span class="name">当前定位：</span>
          <el-button type="primary" class="buttons" plain style="padding: 6px 10px;" @click="locationCity(location)"><i class="el-icon-location"></i>
            <span class="location_text" id="location">{{ location }}</span>
          </el-button>
          <span class="reload cursorP" @click="setLocation"><i class="el-icon-refresh-right"></i></span>
        </div>
        <div class="children">
          <div class="name">其他站点 <span class="tip">点击进入站点，获取更多本地服务</span></div>
          <ul class="child flex-wrap">
            <li @click="goRouter('https://www.cdpee.org.cn/',1)"><a href="javascript:void(0);" title="全国站">全国站</a></li>
            <li @click="goRouter('https://www.qhdpee.org.cn',1)"><a href="javascript:void(0);" title="青海站">青海站</a></li>
            <li @click="goRouter('https://sncjrjy.cn/',1)"><a href="javascript:void(0);" title="陕西站">陕西站</a></li>
            <li @click="goRouter('https://sz.cdpee.org.cn',1)"><a href="javascript:void(0);" title="深圳站">深圳站</a></li>
            <li @click="goRouter('https://henan.cdpee.org.cn',1)"><a href="javascript:void(0);" title="河南站">河南站</a></li>
            <li @click="goRouter('https://hunan.cdpee.org.cn/',1)"><a href="javascript:void(0);" title="湖南站">湖南站</a></li>
            <li @click="goRouter('https://hebei.cdpee.org.cn/',1)"><a href="javascript:void(0);" title="河北站">河北站</a></li>
            <li @click="goRouter('http://fj.cdpee.org.cn/',1, 'fj')"><a href="javascript:void(0);" title="福建">福建站</a></li>
          </ul>
        </div>
        <section class="country" v-if="locationSite === ''">
          <div class="name">全国站 <span class="tip">未开通子站地区请选择下方城市，为您提供更精准的服务</span></div>
          <div class="list" v-for="(n,i) in cityList" :key="i">
            <div class="name" :id="i" v-if="n.length > 0">{{ i }}</div>
            <ul class="child flex-wrap" v-if="n.length > 0">
              <li v-for="(k,s) in n" :key="s">
                <a href="javascript:void(0);" @click="childTab(k),goCount('#location')">{{ k.itemText }}</a>
              </li>
            </ul>
          </div>
        </section>

        <ul class="fixed" v-if="locationSite === ''">
          <li v-for="(n,i) in array" :key="i"><a href="javascript:void(0);" :class="{'active':active == i}" @click="active = i,goCount('#'+n)">{{ n }}</a></li>
        </ul>
        <section class="country" v-else>
          <div class="list">
            <ul class="child flex-wrap">
              <li v-for="(n,i) in pCityList" :key="i">
                <a href="javascript:void(0);" @click="childTab(n),goCount('#location')">{{ n.itemText }}</a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </el-dialog>
    <Location :province="province" :city="location" :is-hide="isShow" @close="isShow = false"></Location>
  </div>
</template>
<script>
import Location from '@/components/search/location'
import { mapMutations } from 'vuex'
export default {
  name: 'allCity',
  props: {
    show: Boolean,
    width: String,
  },
  inject: ['reload'],
  components: {
    Location,
  },
  data() {
    return {
      location: '正在定位中...',
      locationSelect: '暂无选择城市',
      cityList: [],
      active: -1,
      array: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'J',
        'K',
        'L',
        'M',
        'N',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'W',
        'X',
        'Y',
        'Z',
      ],
      locationSite: '',
      pCityList: [],
      province: '',
      isShow: false,
    }
  },
  methods: {
    ...mapMutations(['getAllCity']),
    setLocation() {
      //获取城市的方法
      this.location = '正在定位中...'
      let that = this
      var myCity = new BMapGL.LocalCity()
      myCity.get(function (result) {
        if (result) {
          that.location = result.name
          localStorage.setItem('locationText', that.location)
          if (that.locationSelect == '暂无选择城市') {
            localStorage.setItem('locationSelect', that.location)
          }
        } else {
          // IP定位失败后尝试使用浏览器定位
          that.bybrowserPostion(that)
        }
      })
    },
    bybrowserPostion(that) {
      var geolocation = new BMapGL.Geolocation()
      geolocation.getCurrentPosition(function (position) {
        if (position) {
          let locationProvince = localStorage.getItem('locationProvince') || '' //是否之前做过省份定位
          let city = position.address.city || '全国' //获取城市信息
          that.province = position.address.province //获取省份信息
          that.location = city
          localStorage.setItem('locationProvince', that.province)
          localStorage.setItem('locationText', that.location)
          if (that.locationSelect == '暂无选择城市') {
            localStorage.setItem('locationSelect', that.location)
          }
        } else {
          console.error('failed' + this.getStatus())
        }
      })
      // 旧版定位代码
      // const geolocation = new BMap.Geolocation();
      // let that = this;
      // geolocation.getCurrentPosition(position => {
      //   let locationProvince = localStorage.getItem('locationProvince') || ''; //是否之前做过省份定位
      //   let city = position.address.city || '全国'; //获取城市信息
      //   this.province = position.address.province; //获取省份信息
      //   this.location = city;
      //   localStorage.setItem('locationProvince', this.province);
      //   localStorage.setItem('locationText', this.location);
      //   if (this.locationSelect == '暂无选择城市') {
      //     localStorage.setItem('locationSelect', this.location);
      //   }
      //   // this.childTab(city)
      // }, err => {
      //   this.location = '地址获取失败';
      //   setTimeout(() => {
      //     this.location = '全国';
      //   }, 3000)
      // }, {provider: 'baidu'})
    },
    //根据省查询市
    async provinceidCity() {
      let ins = localStorage.getItem('locationSite')
      let res
      new Promise(async (resolve, reject) => {
        if (ins) {
          if (ins == '河南省') {
            res = await this.$api.henanCity()
          } else if (ins == '湖南省') {
            res = await this.$api.hunanCity()
          } else if (ins == '陕西省') {
            res = await this.$api.shanxiCity()
          } else if (ins == '甘肃省') {
            res = await this.$api.lanzhouCity()
          }
          resolve()
          this.pCityList = res.data.data
        } else {
          reject()
        }
      })
    },
    city() {
      let that = this
      this.$api.cityAllApi().then((res) => {
        this.cityList = res.data.data
      })
    },
    locationCity(text) {
      if (text !== '正在定位中...') {
        this.locationSelect = text
        localStorage.removeItem('location')
        localStorage.setItem('locationSelect', text)
        try {
          this.$api.getCodeByCityNameApi({ city: text }).then((res) => {
            if (res.data.success) {
              localStorage.setItem('location', res.data.data)
              console.log('定位code', res.data.data)
              this.handleClose()
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    childTab(text) {
      let city = JSON.stringify(text)
      this.locationSelect = text.itemText
      this.$store.commit('GET_LOCATION', text)
      this.$store.commit('locationSelect', city)
      localStorage.setItem('locationSelect', text.itemText)
      localStorage.setItem('location', city)
      this.handleClose()
    },
    handleClose() {
      this.$emit('close', false)
      this.reload()
    },
    goCount(selector) {
      let anchor = this.$el.querySelector(selector)
      let body = this.$el.querySelector('.content')
      body.scrollTop = anchor.offsetTop
    },
    goRouter(url, type, siteName) {
      if (type == 1) {
        if (localStorage.getItem('access_token')) {
          url =
            url +
            (siteName != 'fj'
              ? '?token=' + localStorage.getItem('access_token')
              : '')
          window.open(url)
        } else {
          window.open(url)
        }
      } else {
        this.$router.push({ path: url })
        this.reload()
      }
    },
  },
  created() {
    let location = localStorage.getItem('locationText')
    let locationSelect = localStorage.getItem('locationSelect')
    this.locationSite = localStorage.getItem('locationSite') || ''
    this.$nextTick(() => {
      this.$getChildCity(this.province, this.locationSite)
    })
    if (locationSelect) {
      this.locationSelect = locationSelect
    }
    if (this.locationSite) {
      this.provinceidCity()
    }
    if (location) {
      this.location = location
    } else {
      this.setLocation()
    }
    this.city()
    //  判断定位
    let province = localStorage.getItem('locationProvince')
    if (this.locationSite === '河南省') {
      if (this.province === this.locationSite) {
        this.isShow = false
      }
    }
  },
}
</script>

<style scoped lang="less">
.city {
  /deep/ .el-dialog {
    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        display: none;
      }
    }

    .title {
      height: 50px;
      padding: 0 30px;
      background: #00924c;
      justify-content: center;
      align-items: center;

      div {
        flex: 1;
        font-size: 16px;
        color: white;
      }

      .close {
        text-align: right;
        cursor: pointer;
        transform: translateX(20px);

        i {
          font-size: 28px;
        }
      }
    }

    .el-dialog__body {
      position: relative;
      padding: 0;

      ::-webkit-scrollbar {
        // 直接复制黏贴到样式页.css，完美解决
        display: none;
        /* background-color:transparent; */
      }

      .content {
        height: 680px;
        overflow: scroll;
        padding: 30px 0;

        .location {
          padding: 0 30px;

          /deep/ .buttons {
            padding: 6px 15px;
            transform: translateY(-5px);
          }

          .name {
            font-size: 16px;
          }

          i {
            font-size: 16px;
          }
        }

        .reload {
          padding-top: 5px;
          padding-left: 10px;

          i {
            color: #00924b;
            font-size: 20px;
            font-weight: bold;
          }
        }

        .children {
          padding: 30px;

          .tip {
            font-size: 12px;
            color: #ff5500;
            padding-left: 10px;
          }

          .child {
            li {
              width: 88px;
              height: 32px;
              border: 1px solid #e1e1e1;
              margin-top: 20px;
              margin-right: 22px;

              a {
                display: block;
                width: 100%;
                height: 100%;
                line-height: 32px;
                text-align: center;
              }
            }

            //li:nth-of-type(5n) {
            //  margin-right: 0;
            //}
          }
        }

        .country {
          padding-bottom: 30px;
          padding-left: 30px;

          .name {
            .tip {
              font-size: 12px;
              color: #ff5500;
              padding-left: 10px;
            }
          }

          .list {
            .name {
              margin-top: 20px;
            }

            .child {
              li {
                min-width: 88px;
                height: 32px;
                border: 1px solid #e1e1e1;
                margin-top: 10px;
                margin-right: 22px;

                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  padding: 0 10px;
                  line-height: 32px;
                  text-align: center;
                }
              }

              /*li:nth-of-type(5n){*/
              /*margin-right: 0;*/
              /*}*/
            }
          }
        }

        .fixed {
          position: absolute;
          top: 10%;
          right: 5px;
          // transform: translateY(-50%);
          li {
            font-size: 16px;
            color: #666666;
            margin: 2px 0;

            a.active {
              color: #00924c;
            }
          }

          li:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
