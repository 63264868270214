<template>
  <el-dropdown>
    <div class="flexs" @click="goRouter">
      <span class="el-dropdown-link line_clamp1" :title="userInfo.userName">{{ userInfo.userName }}</span>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </div>
    <!-- <div class="flexs" v-if="touristType == 2 && type==4"    @click="goRouter('/companyLogin/authentication')">
            <span class="el-dropdown-link line_clamp1" :title="username">{{username}}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
        </div> -->
    <el-dropdown-menu slot="dropdown" v-if="type == 1 || (type == 4 && touristType == 1)">
      <router-link :to="type == 4?'/center/indexs':'/center/index'">
        <el-dropdown-item>个人中心</el-dropdown-item>
      </router-link>
      <!-- <el-menu-item index="/center/annual/">
          <img src="~/static/center/qiuzhi.png" alt class="block_inline" />
          <span slot="title">年审员培训</span>
      </el-menu-item> -->
      <router-link
          :to="{path:'/center/resume',query: {id: userInfo.id,
            userType:type}}"
          v-if="type == 1"
      >
        <el-dropdown-item>我的简历</el-dropdown-item>
      </router-link>

      <router-link to="/center/work/" v-if="type == 1">
        <el-dropdown-item>我的投递</el-dropdown-item>
      </router-link>

      <router-link to="/center/training/" v-if="type == 1">
        <el-dropdown-item>我的培训</el-dropdown-item>
      </router-link>

      <router-link to="/center/myask/" v-if="location === ''">
        <el-dropdown-item>我的问答</el-dropdown-item>
      </router-link>

      <router-link to="/center/column/" v-if="location === ''">
        <el-dropdown-item>我的专栏</el-dropdown-item>
      </router-link>

      <router-link to="/center/collect/" v-if="type == 1">
        <el-dropdown-item>我的收藏</el-dropdown-item>
      </router-link>

      <router-link to="/center/myNeeds/" v-if="type == 1">
        <el-dropdown-item>我的需求</el-dropdown-item>
      </router-link>

      <router-link to="/setting/" v-if="type == 1">
        <el-dropdown-item>功能设置</el-dropdown-item>
      </router-link>

      <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
    <!-- 企业中心 -->
    <el-dropdown-menu slot="dropdown" class="width130" v-else-if="type == 2">
      <router-link to="/enterprise/centre/index/">
        <el-dropdown-item>企业中心</el-dropdown-item>
      </router-link>

      <router-link to="/enterprise/centre/infomanage/">
        <el-dropdown-item>信息管理</el-dropdown-item>
      </router-link>

      <!--      <router-link to="/enterprise/centre/myask/">-->
      <!--        <el-dropdown-item>招聘会</el-dropdown-item>-->
      <!--      </router-link>-->

      <router-link to="/enterprise/centre/myask/" v-if="location === ''">
        <el-dropdown-item>我的问答</el-dropdown-item>
      </router-link>

      <router-link to="/enterprise/centre/mysection/" v-if="location === ''">
        <el-dropdown-item>我的专栏</el-dropdown-item>
      </router-link>

      <router-link to="/enterprise/centre/myneeds/">
        <el-dropdown-item>我的需求</el-dropdown-item>
      </router-link>

      <router-link to="/enterprise/centre/setting/phone/">
        <el-dropdown-item>功能设置</el-dropdown-item>
      </router-link>

      <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
<el-dropdown-menu slot="dropdown" class="width130" v-else>
  <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
</el-dropdown-menu>
<!--    <el-dropdown-menu slot="dropdown" class="width130" v-if="(touristType == 2 && type == 4) ">-->
<!--      <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>-->
<!--    </el-dropdown-menu>-->
  </el-dropdown>
</template>

<script>
export default {
  name: "HeadDrop",
  inject: ["reload"],
  data() {
    return {
      username: "",
      type: "1",
      touristType: "",
      status: 0,
      isHide: true,
      location: '',
      userInfo:'',
    };
  },
  methods: {
    logout() {
      this.$confirm("确定要退出当前登录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            let res = await this.$api.outLoginApi();
            this.$store.commit("SET_USER_INFO", 1);
            this.$store.commit("SET_TOKEN", "");
            this.$store.commit("GET_USER", "");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("userType");
            this.$message({
              type: "warning",
              message: "退出成功!",
            });
            this.$router.push("/")
            this.$nextTick(() => {
              setTimeout(() => {
                this.reload();
              }, 200)
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出",
            });
          });
    },
    goRouter(url) {
      if(this.type == 2){
        url = '/enterprise/centre/index/';
      } else if (this.type == 4 && this.touristType == 1){
        url = '/center/indexs';
      }else if(this.type == 1){
        url = '/center/index';
      }
      this.$router.push(url)
    },
  },
  created() {
    if (this.$store.state.userType || localStorage.getItem("userType")) {
      this.userInfo = this.$store.state.userType || JSON.parse(localStorage.getItem("userType"));
      this.type = this.userInfo.userType || 1;
      this.touristType = this.userInfo.touristType || 1;
    }
    if (localStorage.getItem("locationSite")) {
      this.location = localStorage.getItem("locationSite") || "";
      if (this.location == "河南省") {
        this.isHide = true;
      } else if (this.location == "湖南省") {
        this.isHide = true;
      }
    }
  },
};
</script>
<style>
.el-dropdown-link {
  cursor: pointer;
  color: #333333;
  max-width: 100px;
  text-align: center;
}

/* .drops{} */
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
