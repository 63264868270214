<template>
  <div class="footer_wrap">
    <div class="footer">
      <div class="link">
        <h2 class="title">友情链接</h2>
        <div class="link_list">
          <a
            v-for="(item, index) in adminLinks"
            :key="index"
            :href="item.linkUrl"
            target="_blank"
            >{{ item.linkName }}</a
          >
        </div>
      </div>
      <div class="content">
        <div class="about">
          <router-link to="/about" target="_blank">关于我们</router-link>
          <router-link to="/about/law" target="_blank">法律声明</router-link>
          <router-link to="/about/accessibility" target="_blank"
            >隐私政策</router-link
          >
          <router-link to="/about/policy" target="_blank"
            >无障碍声明</router-link
          >
          <router-link to="/about/feedback" target="_blank"
            >投诉/反馈</router-link
          >
        </div>
        <div class="intext">
          <p>
            <span
              >网站管理及技术支持：<strong
                >中国残疾人联合会信息中心</strong
              ></span
            ><span>网站运营单位：浙江浙大网新软件产业集团有限公司</span
            ><span>残疾人用户热线：<strong>010-68060691</strong></span
            ><span>企业、服务机构热线：<strong>010-68060651</strong></span>
          </p>
          <p>
            <span>网站备案：京ICP备12035391号-2</span
            ><span>
              版权所有：<strong>{{ text }}</strong>
            </span>
          </p>
        </div>
        <div class="qrcode">
          <ul>
            <li>
              <img title="公众号" src="~/static/shanxi/app.jpg" />
              <a href="http://szadmin.cdpee.org.cn" target="_blank">公众号</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "setting",
  data() {
    return {
      adminLinks: "",
      text: "中国残疾人联合会",
      location: "",
    };
  },
  methods: {
    async getAdminLinks() {
      let res = await this.$axios.get("/api/app-jycy-captcha/getAdminLink");
      if (res.data.success) {
        let data = res.data.data;
        this.adminLinks = data["05"];
      }
    },
  },
  created() {
    this.getAdminLinks();
    this.location = localStorage.getItem("locationSite") || "";
    if (this.location == "甘肃省") {
      this.text = "兰州市残疾人劳动就业服务中心";
    }
  },
};
</script>
<style lang="less">
/* @import "~assets/shanxi/common.css"; */
.footer_wrap {
  background: #212936;
  padding: 0px 0 35px 0;
  .footer {
    font-size: 14px;
    font-family: Microsoft YaHei;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .link {
      display: flex;
      padding: 16px 0 17px 0;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 1200px;
        height: 1px;
        background: #ededed;
      }
      h2 {
        width: 68px;
        height: 20px;
        background: #ffffff;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        color: #212936;
        margin-right: 20px;
      }
      .link_list {
        a {
          color: #ffffff;
          margin-right: 20px;
        }
      }
    }
    .content {
      .about {
        margin: 37px 0 28px 0;
        a {
          color: #ffffff;
          padding-right: 20px;
        }
      }
      .intext {
        color: #999999;
        width: 1100px;
        p {
          margin-bottom: 13px;
          span{
            margin-right: 15px;
          }
        }
      }
      .qrcode {
        position: absolute;
        right: 2px;
        bottom: -2px;
        ul {
          li {
            img {
              width: 88px;
              height: 88px;
              display: block;
            }
            a {
              color: #999999;
              margin: 12px 25px;
            }
          }
        }
      }
    }
  }
}
</style>
