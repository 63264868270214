<template>
  <el-dialog
      class="location"
      title=""
      :visible.sync="isHide"
      width="360px"
      :before-close="handleClose">
    <div class="section">
      <img src="~/static/public/close.png" alt="" class="close" @click="goRouter">
      <img src="~/static/public/location.png" alt="" class="img">
      <p>当前定位为 <span>{{city}}</span></p>
      <p>是否继续打开河南站</p>
    </div>
    <div class="footer" slot="footer">
        <el-button type="primary" plain @click="goRouter">否，前往全国站</el-button>
      <el-button type="primary" @click="setLoaction">是（{{timeText}})</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "location",
  props:{isHide:Boolean,province:'',city:''},
  data() {
    return {
      time:'11',
      timeText:'10s',
    }
  },
  methods:{
    goRouter(){
      this.$router.push({path:'/home'});
      localStorage.setItem('locationProvince','');
      localStorage.setItem('locationSite','');
      this.$store.commit('locationSelect','');
      localStorage.setItem('locationSelect',this.city);
      this.handleClose();
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        this.timeText = this.time+'s';
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0+'s';
        this.setLoaction();
      }
    },
    setLoaction(){
      this.$parent.show = true;
      localStorage.setItem('locationProvince','河南省');
      this.handleClose();
    },
    handleClose() {
      this.$emit('close',false);
    },
  },
  created() {
    if (this.isHide){
      this.timer();
    }
  }
}
</script>

<style scoped lang="less">
  .location{
    text-align: center;
    .section{
      position: relative;
      padding-top: 80px;
      .close{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 16px;
        transition: all .3s;
        cursor: pointer;
      }
      p{
        font-size: 16px;
        line-height: 1.5em;
        span{
          font-weight: bold;
        }
      }
      .close:active{
        transform: scale(.9);
      }
    }
    .footer{
      padding-top: 20px;
      text-align: center;
      .el-button{
        width: 120px;
        height: 40px;
      }
      .el-button:first-child{
        width:140px ;
        margin-right: 20px;
        border: none;
      }
    }
  }
</style>